import React, { useState, useEffect } from "react";
import { message, Form, Input, Button } from "antd";
import { Redirect, useHistory } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { API_URL } from "../API";
import styles from "./login.module.css";

const Login = () => {
  const [form] = useForm();
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const route = useHistory();

  const checkAuth = async () => {
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .post(
          `${API_URL}/api/User/CheckAuth`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          return route.push("/haber");
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            return setPageLoading(false);
          }
        });
    } else {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const onFinish = async () => {
    let data = form.getFieldsValue();
    setLoading(true);
    const rawResponse = await axios.post(
      `${API_URL}/api/User/Login`,
      JSON.stringify({
        email: data.email,
        password: data.password,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (rawResponse.status === 200) {
      setLoading(false);
      const content = await rawResponse.data;
      localStorage.setItem("token", content?.accessToken);
      route.push("/haber");
    } else {
      message.error("Giriş yapılamadı tekrar deneyiniz.");
      setLoading(false);
    }
  };
  if (pageLoading) {
    return <></>;
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.login}>
          <Form
            onFinish={onFinish}
            form={form}
            name="basic"
            initialValues={{ remember: true }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Lütfen email giriniz." }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Şifre"
              name="password"
              rules={[{ required: true, message: "Lütfen şifrenizi giriniz." }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Giriş Yap
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
};
export default Login;
